<template>
  <div>
    <ModuleComponent
      ref="mc"
      name="cropLands"
      @changed="item = $event"
      :module-data="moduleData"
      :hide-filters="hideFilters"
      :no-bread-crumb="noBreadCrumb"
    >
      <template v-slot:customColumn_active="{ rowItem }">
        <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
      </template>
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="12" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.FARMER')">
                <v-select2
                  v-model="filters.farmerId.value"
                  endPoint="accounts"
                  :placeholder="t('GLOBAL.FARMER')"
                  :filters="{
                    accountTypeId: { value: Constants.AccountTypes.Farmer }
                  }"
                  sort="name"
                  @input="filters.landId.value = null"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.LAND')">
                <v-select2
                  v-model="filters.landId.value"
                  endPoint="lands"
                  :placeholder="t('GLOBAL.LAND')"
                  sort="name"
                  :filters="{ farmerId: { value: filters.farmerId.value } }"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.CROPLANDTYPE')">
                <v-select2
                  v-model="filters.cropLandTypeId.value"
                  endPoint="definitions"
                  sort="name"
                  :filters="{
                    definitionTypeId: {
                      value: Constants.DefinitionTypes.CroplandType
                    }
                  }"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  name="name"
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.PRODUCT')">
                <v-select2
                  v-model="filter_productId"
                  endPoint="products"
                  sort="name"
                  :placeholder="t('GLOBAL.PRODUCT')"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.PRODUCTKIND')">
                <v-select2
                  v-model="filters.productKindId.value"
                  endPoint="productKinds"
                  sort="name"
                  :placeholder="t('GLOBAL.PRODUCTKIND')"
                  :filters="{
                    productId: {
                      value: filter_productId
                    }
                  }"
                ></v-select2>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-slot:form="{ item }">
        <div class="mt-3 form-group-mb-0">
          <b-row>
            <b-col cols="12" sm="12" md="6">
              <b-form-group :label="t('GLOBAL.FARMER')">
                <v-select2
                  v-model="item.farmerId"
                  :text="
                    item.land && item.land.farmer && item.land.farmer.account
                      ? item.land.farmer.account.name
                      : ''
                  "
                  endPoint="accounts"
                  :placeholder="t('GLOBAL.FARMER')"
                  :filters="{
                    accountTypeId: { value: Constants.AccountTypes.Farmer }
                  }"
                  sort="name"
                  @input="item.landId = null"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="6">
              <b-form-group :label="t('GLOBAL.LAND')">
                <v-select2
                  v-model="item.landId"
                  :text="item.land ? item.land.name : ''"
                  endPoint="lands"
                  :placeholder="t('GLOBAL.LAND')"
                  sort="name"
                  :filters="{ farmerId: { value: item.farmerId } }"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.AREA')">
                <b-input-group>
                  <b-form-input
                    type="number"
                    min="0"
                    v-model="item.area"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text>m<sup>2</sup></b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input v-model="item.name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.DESCRIPTION')">
                <b-form-input v-model="item.description"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.CROPLANDTYPE')">
                <v-select2
                  v-model="item.cropLandTypeId"
                  :text="item.cropLandType ? item.cropLandType.name : ''"
                  endPoint="definitions"
                  :placeholder="t('GLOBAL.CROPLANDTYPE')"
                  sort="name"
                  :filters="{ definitionTypeId: { value: 1 } }"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.PRODUCT')">
                <v-select2
                  v-model="productId"
                  :text="
                    item.productKind && item.productKind.product
                      ? item.productKind.product.name
                      : ''
                  "
                  endPoint="products"
                  :placeholder="t('GLOBAL.PRODUCT')"
                  sort="name"
                  @input="item.productKindId = null"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.PRODUCTKIND')">
                <v-select2
                  v-model="item.productKindId"
                  :text="
                    item.productKindId && item.productKindId.name
                      ? item.productKind.name
                      : ''
                  "
                  endPoint="productKinds"
                  :placeholder="t('GLOBAL.PRODUCTKIND')"
                  sort="name"
                  :filters="{ productId: { value: productId } }"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.TREEGAP')">
                <b-input-group>
                  <decimal-input v-model="item.treeGap" />
                  <b-input-group-append>
                    <b-input-group-text>m</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.TREECOUNT')">
                <b-input-group>
                  <decimal-input v-model="item.treeCount" />
                  <b-input-group-append>
                    <b-input-group-text>adet</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-checkbox
                size="lg"
                switch
                v-model="item.active"
                name="active"
                >{{ t("GLOBAL.ACTIVE") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-slot:customColumn_land="{ rowItem }">
        Farmer : {{ rowItem.land.farmer.account.name }}<br />
        Arazi : {{ rowItem.land.name }} ({{ rowItem.land.area }})
      </template>
      <template v-slot:customColumn_harvests="{ rowItem }">
        <v-btn
          color="blue-grey"
          x-small
          dark
          class="mr-1"
          @click="showHarvestsOfCropLandDialog(rowItem)"
        >
          <v-icon x-small dark>
            mdi-border-all
          </v-icon>
        </v-btn>
      </template>
    </ModuleComponent>
    <dlg v-model="showHarvests" eager>
      <template v-slot:content>
        <Harvests ref="harvests" hide-filters no-bread-crumb></Harvests>
      </template>
    </dlg>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-CropLand

import Harvests from "./harvests";

export default {
  name: "cropLands",
  components: { Harvests },
  methods: {
    filterByLand(land) {
      this.moduleData.table.filters.landId.value = land.id;
    },
    showHarvestsOfCropLandDialog(cropLand) {
      this.showHarvests = true;
      this.$refs.harvests.filterByCropLand(cropLand);
    }
  },
  data() {
    return {
      showHarvests: false,
      productId: 0,
      filter_productId: 0,
      item: {},
      moduleData: {
        name: "cropLands",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
				            id,
				            land{
				                farmer{account{name}},
			                    name,
			                    area
		                    },
	                        name,
	                        area,
				            cropLandType{name},
				            productKind{
				                name,
				                product{name}
				            },
				            active
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.LAND.D"), value: "land" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.TYPE.D"), value: "cropLandType.name" },
            { text: this.t("GLOBAL.AREA"), value: "area" },
            {
              text: this.t("GLOBAL.PRODUCT.D"),
              value: "productKind.product.name"
            },
            { text: this.t("GLOBAL.PRODUCTKIND.D"), value: "productKind.name" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            },
            { text: this.t("GLOBAL.HARVEST.P"), value: "harvests" }
          ],
          customColumns: ["land", "active", "harvests"],
          filters: {
            name: {
              type: "string",
              searchBy: "contains",
              value: null
            },
            cropLandTypeId: {
              type: "number",
              value: null
            },
            farmerId: {
              type: "number",
              value: null
            },
            landId: {
              type: "number",
              value: null
            },
            productKindId: {
              type: "number",
              value: null
            },
            startDate: {
              type: "date",
              value: null
            },
            endDate: {
              type: "date",
              value: null
            }
          }
        },
        form: {
          maxWidth: "80%"
        },
        fileManager: "cropland_file_groups"
      }
    };
  },
  watch: {
    item(item) {
      this.productId =
        item.productKind &&
        item.productKind.product &&
        item.productKind.product.id !== "undefined"
          ? item.productKind.product.id
          : this.productId;
    }
  }
};
</script>
