<template>
  <ModuleComponent
    ref="mc"
    name="harvests"
    @changed="item = $event"
    :module-data="moduleData"
    :hide-filters="hideFilters"
    :no-bread-crumb="noBreadCrumb"
    @beforeItemLoad="beforeItemLoad"
  >
    <template v-slot:tableRowActions="{ rowItem }"></template>
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.FARMER')">
              <v-select2
                v-model="filters.farmerId.value"
                endPoint="accounts"
                :placeholder="t('GLOBAL.FARMER')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Farmer }
                }"
                sort="name"
                @input="filters.landId.value = filters.cropLandId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.LAND')">
              <v-select2
                v-model="filters.landId.value"
                endPoint="lands"
                :placeholder="t('GLOBAL.LAND')"
                sort="name"
                :filters="{ farmerId: { value: filters.farmerId.value } }"
                @input="filters.cropLandId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.CROPLAND')">
              <v-select2
                v-model="filters.cropLandId.value"
                endPoint="cropLands"
                :placeholder="t('GLOBAL.CROPLAND')"
                sort="name"
                :filters="{ landId: { value: filters.landId.value } }"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.PRODUCT')">
              <v-select2
                v-model="filters.productId.value"
                endPoint="products"
                sort="name"
                :placeholder="t('GLOBAL.PRODUCT')"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.PRODUCTKIND')">
              <v-select2
                v-model="filters.productKindId.value"
                endPoint="productKinds"
                sort="name"
                :placeholder="t('GLOBAL.PRODUCTKIND')"
                :filters="{
                  productId: {
                    value: filters.productId.value
                  }
                }"
              ></v-select2>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.STARTDATE')">
              <BDatePicker
                :id="'startDate'"
                v-model="filters.startDate.value"
                @input="
                  filters.startDate.value = $event;
                  filters.endDate.value = $event;
                "
                hide-header
                show-decade-nav
                :placeholder="t('GLOBAL.STARTDATE')"
              >
              </BDatePicker>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.ENDDATE')">
              <BDatePicker
                :id="'endDate'"
                v-model="filters.endDate.value"
                @input="filters.endDate.value = $event"
                hide-header
                show-decade-nav
                :placeholder="t('GLOBAL.ENDDATE')"
              >
              </BDatePicker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:customColumn_cropLand="{ rowItem }">
      <span class="badge badge-primary mb-1">
        Farmer
      </span>
      {{ rowItem.cropLand.land.farmer.account.name }}<br />
      <span class="badge brown lighten-1 white--text mb-1">
        Arazi
      </span>
      {{ rowItem.cropLand.land.name }} ({{ rowItem.cropLand.land.area }})<br />
      <span class="badge green lighten-2 white--text mb-1">
        Ekim Alanı
      </span>
      {{ rowItem.cropLand.name }}
      <br />
      <span class="badge badge-success"> Ekim Alanı Tipi</span>
      {{ rowItem.cropLand.cropLandType.name }}
    </template>
    <template v-slot:form="{ item }">
      <b-row>
        <b-col cols="12" sm="4" md="4">
          <b-form-group :label="t('GLOBAL.FARMER')">
            <v-select2
              v-model="farmerId"
              :text="
                item.cropLand &&
                item.cropLand.land &&
                item.cropLand.land.farmer &&
                item.cropLand.land.farmer.account
                  ? item.cropLand.land.farmer.account.name
                  : ''
              "
              endPoint="accounts"
              :placeholder="t('GLOBAL.FARMER')"
              :filters="{
                accountTypeId: { value: Constants.AccountTypes.Farmer }
              }"
              sort="name"
              @input="landId = item.cropLandId = null"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="4" md="4">
          <b-form-group :label="t('GLOBAL.LAND')">
            <v-select2
              v-model="landId"
              :text="item.land ? item.land.name : ''"
              endPoint="lands"
              :placeholder="t('GLOBAL.LAND')"
              sort="name"
              :filters="{ farmerId: { value: farmerId } }"
              @input="item.cropLandId = null"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="4" md="4">
          <b-form-group :label="t('GLOBAL.CROPLAND')">
            <v-select2
              v-model="item.cropLandId"
              :text="item.cropLand ? item.cropLand.name : ''"
              endPoint="cropLands"
              :placeholder="t('GLOBAL.CROPLAND')"
              sort="name"
              :filters="{ landId: { value: landId } }"
            ></v-select2>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" sm="12" md="4">
          <b-form-group :label="t('GLOBAL.STARTDATE')">
            <BDatePicker
              :id="'startDate'"
              v-model="item.startDate"
              @input="
                item.startDate = $event;
                item.endDate = $event;
              "
              hide-header
              show-decade-nav
              :placeholder="t('GLOBAL.STARTDATE')"
            >
            </BDatePicker>
          </b-form-group>
        </b-col>
        <b-col cols="6" sm="12" md="4">
          <b-form-group :label="t('GLOBAL.ENDDATE')">
            <BDatePicker
              :id="'endDate'"
              v-model="item.endDate"
              @input="item.endDate = $event"
              hide-header
              show-decade-nav
              :placeholder="t('GLOBAL.ENDDATE')"
            >
            </BDatePicker>
          </b-form-group>
        </b-col>
        <b-col cols="4" sm="12" md="4">
          <b-form-group :label="t('GLOBAL.AMOUNT')">
            <b-input-group>
              <b-form-input
                type="number"
                v-model="item.amount"
                step="1"
                placeholder="0"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>kg</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="4">
          <b-form-group :label="t('GLOBAL.UNITPRICE')">
            <b-input-group>
              <decimal-input v-model="item.unitPrice" />
              <b-input-group-append>
                <b-input-group-text>TL</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <img :src="qrCodeUrl" />
          <a :href="qrCodeLink" target="_blank">{{ qrCodeLink }}</a>
        </b-col>
        <b-col cols="9"></b-col>
      </b-row>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Harvest
export default {
  name: "harvests",
  methods: {
    beforeItemLoad(item) {
      this.qrCodeLink = this.constQrCodeLink + item.id;
      this.qrCodeUrl =
        this.constQrCodeUrl + encodeURIComponent(this.qrCodeLink);
    },
    filterByCropLand(cropLand) {
      this.moduleData.table.filters.cropLandId.value = cropLand.id;
    }
  },
  data() {
    return {
      qrCodeLink: "",
      constQrCodeLink: "https://qr.uzmanciftci.com/",
      qrCodeUrl: "",
      constQrCodeUrl:
        "https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=",
      farmerId: 0,
      landId: 0,
      cropLandId: 0,
      productId: 0,
      item: {},
      moduleData: {
        name: "harvests",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
				            id,
                          cropLand{
                              name,
                              land{
                        farmer{
                            account{name}
                            },
                              name,
                              area
                        },
                          name,
                          area,
                    cropLandType{name},
                    productKind{
                        name,
                        product{name}
                    }
                          },
                          startDate,
                          endDate,
                          amount,
                          unitPrice
						`,
          headers: [
            { text: "ID", value: "id", width: "50" },
            { text: this.t("GLOBAL.CROPLAND"), value: "cropLand" },
            {
              text: this.t("GLOBAL.PRODUCT"),
              value: "cropLand.productKind.product.name"
            },
            {
              text: this.t("GLOBAL.PRODUCTKIND"),
              value: "cropLand.productKind.name"
            },
            { text: this.t("GLOBAL.STARTDATE"), value: "startDate" },
            { text: this.t("GLOBAL.ENDDATE"), value: "endDate" },
            { text: this.t("GLOBAL.AMOUNT"), value: "amount" },
            { text: this.t("GLOBAL.UNITPRICE"), value: "unitPrice" }
          ],
          customColumns: ["cropLand"],
          filters: {
            farmerId: {
              type: "number",
              value: null
            },
            landId: {
              type: "number",
              value: null
            },
            cropLandId: {
              type: "number",
              value: null
            },
            productId: {
              type: "number",
              value: null
            },
            productKindId: {
              type: "number",
              value: null
            },
            startDate: {
              type: "date",
              value: null
            },
            endDate: {
              type: "date",
              value: null
            }
          }
        },
        form: {
          maxWidth: "90%"
        },
        fileManager: "harvest_file_groups"
      }
    };
  },
  watch: {
    item(item) {
      this.farmerId =
        item.cropLand &&
        item.cropLand.land &&
        item.cropLand.land.farmer &&
        item.cropLand.land.farmer.account
          ? item.cropLand.land.farmer.account.id
          : this.farmerId;
      this.landId =
        item.cropLand && item.cropLand.land
          ? item.cropLand.land.id
          : this.landId;
      this.cropLandId = item.cropLand ? item.cropLand.id : this.cropLandId;
      this.productId =
        item.cropLand &&
        item.cropLand.productKind &&
        item.cropLand.productKind.product
          ? item.cropLand.productKind.product.id
          : this.productId;
    }
  }
};
</script>
