var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleComponent',{ref:"mc",attrs:{"name":"harvests","module-data":_vm.moduleData,"hide-filters":_vm.hideFilters,"no-bread-crumb":_vm.noBreadCrumb},on:{"changed":function($event){_vm.item = $event},"beforeItemLoad":_vm.beforeItemLoad},scopedSlots:_vm._u([{key:"tableRowActions",fn:function(ref){
var rowItem = ref.rowItem;
return undefined}},{key:"tableFilters",fn:function(ref){
var filters = ref.filters;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.FARMER')}},[_c('v-select2',{attrs:{"endPoint":"accounts","placeholder":_vm.t('GLOBAL.FARMER'),"filters":{
                accountTypeId: { value: _vm.Constants.AccountTypes.Farmer }
              },"sort":"name"},on:{"input":function($event){filters.landId.value = filters.cropLandId.value = null}},model:{value:(filters.farmerId.value),callback:function ($$v) {_vm.$set(filters.farmerId, "value", $$v)},expression:"filters.farmerId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.LAND')}},[_c('v-select2',{attrs:{"endPoint":"lands","placeholder":_vm.t('GLOBAL.LAND'),"sort":"name","filters":{ farmerId: { value: filters.farmerId.value } }},on:{"input":function($event){filters.cropLandId.value = null}},model:{value:(filters.landId.value),callback:function ($$v) {_vm.$set(filters.landId, "value", $$v)},expression:"filters.landId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CROPLAND')}},[_c('v-select2',{attrs:{"endPoint":"cropLands","placeholder":_vm.t('GLOBAL.CROPLAND'),"sort":"name","filters":{ landId: { value: filters.landId.value } }},model:{value:(filters.cropLandId.value),callback:function ($$v) {_vm.$set(filters.cropLandId, "value", $$v)},expression:"filters.cropLandId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.PRODUCT')}},[_c('v-select2',{attrs:{"endPoint":"products","sort":"name","placeholder":_vm.t('GLOBAL.PRODUCT')},model:{value:(filters.productId.value),callback:function ($$v) {_vm.$set(filters.productId, "value", $$v)},expression:"filters.productId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.PRODUCTKIND')}},[_c('v-select2',{attrs:{"endPoint":"productKinds","sort":"name","placeholder":_vm.t('GLOBAL.PRODUCTKIND'),"filters":{
                productId: {
                  value: filters.productId.value
                }
              }},model:{value:(filters.productKindId.value),callback:function ($$v) {_vm.$set(filters.productKindId, "value", $$v)},expression:"filters.productKindId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"6","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.STARTDATE')}},[_c('BDatePicker',{attrs:{"id":'startDate',"hide-header":"","show-decade-nav":"","placeholder":_vm.t('GLOBAL.STARTDATE')},on:{"input":function($event){filters.startDate.value = $event;
                filters.endDate.value = $event;}},model:{value:(filters.startDate.value),callback:function ($$v) {_vm.$set(filters.startDate, "value", $$v)},expression:"filters.startDate.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"6","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.ENDDATE')}},[_c('BDatePicker',{attrs:{"id":'endDate',"hide-header":"","show-decade-nav":"","placeholder":_vm.t('GLOBAL.ENDDATE')},on:{"input":function($event){filters.endDate.value = $event}},model:{value:(filters.endDate.value),callback:function ($$v) {_vm.$set(filters.endDate, "value", $$v)},expression:"filters.endDate.value"}})],1)],1)],1)],1)]}},{key:"customColumn_cropLand",fn:function(ref){
                var rowItem = ref.rowItem;
return [_c('span',{staticClass:"badge badge-primary mb-1"},[_vm._v(" Farmer ")]),_vm._v(" "+_vm._s(rowItem.cropLand.land.farmer.account.name)),_c('br'),_c('span',{staticClass:"badge brown lighten-1 white--text mb-1"},[_vm._v(" Arazi ")]),_vm._v(" "+_vm._s(rowItem.cropLand.land.name)+" ("+_vm._s(rowItem.cropLand.land.area)+")"),_c('br'),_c('span',{staticClass:"badge green lighten-2 white--text mb-1"},[_vm._v(" Ekim Alanı ")]),_vm._v(" "+_vm._s(rowItem.cropLand.name)+" "),_c('br'),_c('span',{staticClass:"badge badge-success"},[_vm._v(" Ekim Alanı Tipi")]),_vm._v(" "+_vm._s(rowItem.cropLand.cropLandType.name)+" ")]}},{key:"form",fn:function(ref){
                var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.FARMER')}},[_c('v-select2',{attrs:{"text":item.cropLand &&
              item.cropLand.land &&
              item.cropLand.land.farmer &&
              item.cropLand.land.farmer.account
                ? item.cropLand.land.farmer.account.name
                : '',"endPoint":"accounts","placeholder":_vm.t('GLOBAL.FARMER'),"filters":{
              accountTypeId: { value: _vm.Constants.AccountTypes.Farmer }
            },"sort":"name"},on:{"input":function($event){_vm.landId = item.cropLandId = null}},model:{value:(_vm.farmerId),callback:function ($$v) {_vm.farmerId=$$v},expression:"farmerId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.LAND')}},[_c('v-select2',{attrs:{"text":item.land ? item.land.name : '',"endPoint":"lands","placeholder":_vm.t('GLOBAL.LAND'),"sort":"name","filters":{ farmerId: { value: _vm.farmerId } }},on:{"input":function($event){item.cropLandId = null}},model:{value:(_vm.landId),callback:function ($$v) {_vm.landId=$$v},expression:"landId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CROPLAND')}},[_c('v-select2',{attrs:{"text":item.cropLand ? item.cropLand.name : '',"endPoint":"cropLands","placeholder":_vm.t('GLOBAL.CROPLAND'),"sort":"name","filters":{ landId: { value: _vm.landId } }},model:{value:(item.cropLandId),callback:function ($$v) {_vm.$set(item, "cropLandId", $$v)},expression:"item.cropLandId"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.STARTDATE')}},[_c('BDatePicker',{attrs:{"id":'startDate',"hide-header":"","show-decade-nav":"","placeholder":_vm.t('GLOBAL.STARTDATE')},on:{"input":function($event){item.startDate = $event;
              item.endDate = $event;}},model:{value:(item.startDate),callback:function ($$v) {_vm.$set(item, "startDate", $$v)},expression:"item.startDate"}})],1)],1),_c('b-col',{attrs:{"cols":"6","sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.ENDDATE')}},[_c('BDatePicker',{attrs:{"id":'endDate',"hide-header":"","show-decade-nav":"","placeholder":_vm.t('GLOBAL.ENDDATE')},on:{"input":function($event){item.endDate = $event}},model:{value:(item.endDate),callback:function ($$v) {_vm.$set(item, "endDate", $$v)},expression:"item.endDate"}})],1)],1),_c('b-col',{attrs:{"cols":"4","sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.AMOUNT')}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","step":"1","placeholder":"0"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v("kg")])],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.UNITPRICE')}},[_c('b-input-group',[_c('decimal-input',{model:{value:(item.unitPrice),callback:function ($$v) {_vm.$set(item, "unitPrice", $$v)},expression:"item.unitPrice"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v("TL")])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('img',{attrs:{"src":_vm.qrCodeUrl}}),_c('a',{attrs:{"href":_vm.qrCodeLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.qrCodeLink))])]),_c('b-col',{attrs:{"cols":"9"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }